import _get from 'lodash/get';
import {
    GET_GALLERY_DETAILS_BY_ID_SUCCESS,
    GET_PRODUCT_LIST_SUCCESS,
} from '../actionTypes/GalleryActionTypes';

const preparePaginationObject = (action) => {
    const organicZoneInfo = _get(action.galleryDetails, 'organicZoneInfo');
    if (organicZoneInfo) {
        const pagination = {
            hasNextPage: organicZoneInfo.nextPage,
            hasPrevPage: organicZoneInfo.prevPage,
            nextPageOffset: organicZoneInfo.nextPage
                ? action.productsPerPage * organicZoneInfo.currentPage
                : organicZoneInfo.nextPageOffset,
            prevPageOffset: action.productsPerPage * (organicZoneInfo.currentPage - 1) + 1,
            selectedPageNumber: organicZoneInfo.currentPage,
            totalProductsCount: organicZoneInfo.totalNumRecs,
            totalPages: organicZoneInfo.totalPages,
            showDropdown: organicZoneInfo.totalPages > 1,
            productsPerPage: action.productsPerPage,
            // For multiple thumbnails
            ppConsumed: organicZoneInfo?.ppConsumed,
            multipleThumbNail: organicZoneInfo?.multipleThumbNail,
            nextPageMktTiles: organicZoneInfo?.nextPageMktTiles,
        };
        return pagination;
    }
    return {};
};

// istanbul-ignore-next
export default function loadPaginationReducer(state = false, action) {
    // NOSONAR
    switch (action.type) {
        case GET_GALLERY_DETAILS_BY_ID_SUCCESS: {
            return { ...state, pagination: preparePaginationObject(action) };
        }
        case GET_PRODUCT_LIST_SUCCESS:
        default:
            return state;
    }
}
