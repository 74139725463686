import _get from 'lodash/get';
import _chunk from 'lodash/chunk';
import _size from 'lodash/size';
import _find from 'lodash/find';
import _omit from 'lodash/omit';
import { isMobileWidth } from 'yoda-core-components/lib/helpers/Utils/mediaQuery';
import LocalStorage from 'yoda-core-components/lib/helpers/LocalStorage/LocalStorage';
import { getDefaultOffSet } from 'yoda-site-components/lib/helpers/Utils/scrollPosition';
import { GET_PRODUCT_LIST_SUCCESS, MERGE_PRODUCTS } from '../actionTypes/GalleryActionTypes';
import { GET_GALLERY_IRIS_GRID_CONTENT } from '../actionTypes/GalleryIrisActionTypes';
import { ECOREBATES_GET_SUCCESS } from '../actionTypes/EcoRebatesActionTypes';
import { updateQueryStringParameter, isFirstPageWithoutPrev } from '../utils';
import { scrollTitleIntoView } from '../utils/scrollTitleIntoView';

/**
 * @description
 * Generates the product list based on the availability of admonetization
 */

const MARKETING_SLOT_POSITIONS = [4, 15];
const PRODUCT_CHUNK_SIZE = 12;
const SPONSOREDPRODUCTS_SIZE = 47;
let sponsouredIndex = 0;

/* istanbul ignore next */
export const getBadgeParams = (product) => {
    const badgeCheck = (badges) => _find(badges, (badge) => badge.priority === 1 && badge.name);
    const fallback = () => {
        let badge = null;
        if (product.bundleStatusBadge && product.bundleSavePrice) {
            badge = {
                name: `Save up to $${product.bundleSavePrice}`,
            };
        } else if (product.newArrival) {
            badge = {
                name: 'new',
            };
        }
        return badge;
    };
    let badgeParam = '';
    /* eslint no-param-reassign: ["error", { "props": false }] */
    product.activeUpperBadge = badgeCheck(product.upperLeftBadge) || fallback();
    product.activeBottomBadge = badgeCheck(product.bottomBadges);
    if (product.activeUpperBadge && product.activeBottomBadge) {
        badgeParam = `${product.activeUpperBadge.urlParam}%7C${product.activeBottomBadge.urlParam}`;
    } else if (product.activeBottomBadge) {
        badgeParam = `${product.activeBottomBadge.urlParam}`;
    } else if (product.activeUpperBadge) {
        badgeParam = `${product.activeUpperBadge.urlParam}`;
    }
    return updateQueryStringParameter(product.pdpUrl, 'badge', badgeParam);
};

export const chunkProducts = (
    list,
    enableVisualRelatedSearch = false,
    enableDynamicVisNav = false,
    pageType,
    size = PRODUCT_CHUNK_SIZE,
    deviceType,
    isNative
) => {
    let productsList = [];
    const storedScrollTo = LocalStorage.getData('galleryScrollTo');
    const { galleryScrollTo } = getDefaultOffSet('galleryFavoriteScrollTo');
    /* istanbul ignore next */
    if ((storedScrollTo || galleryScrollTo) && !__SERVER__ && deviceType?.isDesktop) {
        const galleryProduct = list?.find((item) => {
            const ppID = item?.sponsoredProduct ? `${item?.skuId}-sp` : item?.skuId;
            return ppID && (storedScrollTo === ppID || galleryScrollTo === ppID);
        });

        if (galleryProduct === undefined) {
            scrollTitleIntoView('#gallery-product-list', deviceType?.isDesktop, isNative);
            LocalStorage.removeData('galleryFavoriteScrollTo', true, '');
            LocalStorage.removeData('galleryScrollTo');
            LocalStorage.removeData('galleryScrollToOffSet');
        }
    }

    if (_size(list) > size) {
        // If there are more than PRODUCT_CHUNK_SIZE products, then the admonetization should be added after the PRODUCT_CHUNK_SIZEth product
        // So, we are splitting the original array into chunks based on the index to load a separate 'List'
        const chunks = _chunk(list, size);

        for (const chunk in chunks) {
            //eslint-disable-line
            if (chunk) {
                productsList.push({
                    productList: chunks[chunk],
                });
            }
        }
        /*
         * YODATOF-73 - Moving Monetization Ads
         * Move it after 12 products if only on 12 products are present
         * Move it after 24 products otherwise
         */
        const adMonetizationIndex =
            productsList.length > 1 &&
            ((enableVisualRelatedSearch && pageType === 's') ||
                (enableDynamicVisNav && pageType === 'g'))
                ? 2
                : 1;
        productsList.splice(adMonetizationIndex, 0, {
            adMonetization: true,
        });
    } else {
        productsList = [
            { productList: list },
            {
                adMonetization: true,
            },
        ];
    }

    return productsList;
};

export const getProductList = (
    galleryDetails,
    marketingTiles = 0,
    disableOrphanProducts,
    productsPerPage,
    enableVisualRelatedSearch,
    enableDynamicVisNav,
    pageType,
    sponsouredgalleryData = [],
    sponsouredProductSlotConfig = { mobile: [3, 7, 11], DeskTop: [2, 5, 8, 11] },
    enableSponsouredProduct = false,
    enableSponsoredProductsOverflow = false,
    enableMultipleThumbnails = false,
    deviceType,
    isNative = false
) => {
    const marketingSlotPosition = MARKETING_SLOT_POSITIONS;
    const sponsoredProductsPosition = !isMobileWidth()
        ? sponsouredProductSlotConfig.DeskTop
        : sponsouredProductSlotConfig.mobile;

    const list = [];
    const products = [...galleryDetails.organicZoneInfo.products];
    sponsouredIndex = 0;
    let marketingSlotsCreatedCount = 0;
    const { hasMultipleThumbnails = false } = galleryDetails;
    if (
        !hasMultipleThumbnails &&
        !disableOrphanProducts &&
        marketingTiles &&
        isFirstPageWithoutPrev() &&
        products.length + marketingTiles > productsPerPage
    ) {
        products.splice(products.length - marketingTiles, marketingTiles);
    }

    for (let ind = 0; ind < products.length; ind += 1) {
        let val = products[ind];

        if (val.pdpUrl) {
            val.pdpUrl = getBadgeParams(val);
        }

        // Check for any marketing slots that we received and insert them at appropriate position.
        if (
            marketingTiles &&
            marketingTiles > marketingSlotsCreatedCount &&
            marketingSlotPosition.indexOf(ind) >= 0
        ) {
            const slotval = {
                marketingSlot: {
                    slotPosition: marketingSlotsCreatedCount,
                },
            };
            marketingSlotsCreatedCount += 1;
            products.splice(ind, 0, slotval);
            val = slotval;
        }

        if (
            enableSponsouredProduct &&
            sponsouredgalleryData?.length > 0 &&
            sponsoredProductsPosition.indexOf(ind) >= 0 &&
            sponsouredgalleryData[sponsouredIndex] !== undefined
        ) {
            products.splice(ind, 0, sponsouredgalleryData[sponsouredIndex]);
            val = sponsouredgalleryData[sponsouredIndex];
            sponsouredIndex += 1;
        }

        val.index = ind + 1;
        list.push(val);

        if (enableSponsouredProduct && SPONSOREDPRODUCTS_SIZE === ind && !hasMultipleThumbnails) {
            break;
        }
    }

    // add in criteo sponsored product to last slot on page if it's one of the config slots
    if (
        enableSponsouredProduct &&
        !enableSponsoredProductsOverflow &&
        sponsouredgalleryData?.length > 0 &&
        sponsoredProductsPosition.indexOf(products.length) >= 0 &&
        sponsouredgalleryData[sponsouredIndex] !== undefined
    ) {
        const val = sponsouredgalleryData[sponsouredIndex];
        val.index = products.length;
        sponsouredIndex += 1;
        list.push(val);
    }

    // add in all remaining criteo sponsored products to end of organic products if FF is enabled
    if (
        enableSponsouredProduct &&
        enableSponsoredProductsOverflow &&
        sponsouredgalleryData?.length > 0
    ) {
        const remainingSponsoredGalleryData = sponsouredgalleryData.slice(
            sponsouredIndex,
            sponsoredProductsPosition?.length
        );

        if (remainingSponsoredGalleryData?.length > 0) {
            let newIndex = list.length;
            remainingSponsoredGalleryData.forEach((sponsoredProduct) => {
                newIndex += 1;
                sponsoredProduct.index = newIndex;
                sponsouredIndex += 1;
                list.push(sponsoredProduct);
            });
        }

        // re-check for any marketing slots that we received and insert them at appropriate position.
        if (marketingTiles && marketingTiles > marketingSlotsCreatedCount) {
            marketingSlotPosition?.forEach((mktTilePosition) => {
                if (list?.length >= mktTilePosition) {
                    const slotval = {
                        marketingSlot: {
                            slotPosition: marketingSlotsCreatedCount,
                        },
                        index: mktTilePosition + 1,
                    };
                    marketingSlotsCreatedCount += 1;
                    list.splice(mktTilePosition, 0, slotval);
                }
            });
        }
    }

    return chunkProducts(
        list,
        enableVisualRelatedSearch,
        enableDynamicVisNav,
        pageType,
        PRODUCT_CHUNK_SIZE,
        deviceType,
        isNative
    );
};

export const isMarketTile = (marketingTiles, index) =>
    !!(marketingTiles.length && MARKETING_SLOT_POSITIONS.includes(index));

export const mergeProducts = (itemsPerRow, organicProducts = [], criteoProducts = []) => {
    // avoid merging if there are 2 or less columns in grid
    // if (itemsPerRow <= 2) {
    //     return organicProducts;
    // }

    const sponsoredProducts = criteoProducts.slice();
    const mergedProductList = [];
    const marketingTiles = [];

    // flatter productList products and extract marketing tiles
    const products = []
        .concat(...organicProducts.map(({ productList }) => productList || []))
        .filter((product) => {
            product.marketingSlot && marketingTiles.push(product);
            return !product.marketingSlot;
        });

    const productsLength = products.length;

    for (let i = 0; i < productsLength + criteoProducts.length; i += 1) {
        if (!products.length) {
            continue; // eslint-disable-line
        }

        // push rest of products if there is no any criteo products
        if (!sponsoredProducts.length && !marketingTiles.length) {
            mergedProductList.push(...products.slice(0, products.length));
            break;
        }

        // check if it's place for marketing zone and push it
        if (isMarketTile(marketingTiles, i)) {
            mergedProductList.push(marketingTiles.splice(0, 1)[0]);
            continue; // eslint-disable-line
        }

        // check if it's position for sponsored product, push it with next product and increment counter
        if (!((i + 1) % itemsPerRow)) {
            const sponsoredProd = sponsoredProducts.splice(0, 1)[0];
            sponsoredProd && mergedProductList.push(sponsoredProd);
            mergedProductList.push(
                isMarketTile(marketingTiles, i + 1)
                    ? marketingTiles.splice(0, 1)[0]
                    : products.splice(0, 1)[0]
            );
            i += 1;
        } else {
            mergedProductList.push(products.splice(0, 1)[0]);
        }
    }

    return chunkProducts(mergedProductList);
};
const defaultState = {
    bvAdsDivId: null,
    marketingTiles: 0,
    galleryDetails: {},
    products: [],
    productListLoaded: false,
};
export default function productListReducer(state = defaultState, action) {
    // NOSONAR
    switch (action.type) {
        case GET_GALLERY_IRIS_GRID_CONTENT: {
            const marketingSlots = _get(action.payload, 'marketingSlot', []);
            const disableOrphanProducts = _get(action.payload, 'disableOrphanProducts', false);
            const enableVisualRelatedSearch = _get(
                action.payload,
                'enableVisualRelatedSearch',
                false
            );
            const enableDynamicVisNav = _get(action.payload, 'enableDynamicVisNav', false);
            const productsPerPage = _get(action.payload, 'productsPerPage', 48);
            const pageType = _get(action.payload, 'pageType', 'g');
            const productsWithMarketingSlots = state.products;
            const newState = {
                marketingTiles: marketingSlots.length,
            };
            /* istanbul ignore next */
            if (
                productsWithMarketingSlots &&
                productsWithMarketingSlots.length &&
                newState.marketingTiles !== state.marketingTiles
            ) {
                newState.products = getProductList(
                    state.galleryDetails,
                    marketingSlots.length,
                    disableOrphanProducts,
                    productsPerPage,
                    enableVisualRelatedSearch,
                    enableDynamicVisNav,
                    pageType
                );
            }
            if (newState.marketingTiles !== state.marketingTiles) {
                return { ...state, ...newState };
            }
            return state;
        }

        case GET_PRODUCT_LIST_SUCCESS: {
            let products = [];
            const disableOrphanProducts = _get(action, 'disableOrphanProducts', false);
            const enableVisualRelatedSearch = _get(action, 'enableVisualRelatedSearch', false);
            const enableDynamicVisNav = _get(action, 'enableDynamicVisNav', false);
            const productsPerPage = _get(action.payload, 'productsPerPage', 48);
            const pageType = _get(action, 'pageType', 'g');
            const enableSponsouredProduct = _get(action, 'enableSponsouredProduct', false);
            const enableSponsoredProductsOverflow = _get(
                action,
                'enableSponsoredProductsOverflow',
                false
            );
            const enableMultipleThumbnails = _get(action, 'enableMultipleThumbnails', false);
            const deviceType = _get(action, 'deviceType', {});
            const isNative = _get(action, 'isNative', false);
            if (
                action.galleryDetails &&
                action.galleryDetails.organicZoneInfo &&
                _size(action.galleryDetails.organicZoneInfo.products)
            ) {
                products = getProductList(
                    action.galleryDetails,
                    state.marketingTiles,
                    disableOrphanProducts,
                    productsPerPage,
                    enableVisualRelatedSearch,
                    enableDynamicVisNav,
                    pageType,
                    action?.sponsouredgalleryData?.qualifiedProducts,
                    action?.sponsouredProductSlotConfig,
                    enableSponsouredProduct,
                    enableSponsoredProductsOverflow,
                    enableMultipleThumbnails,
                    deviceType,
                    isNative
                );
            }

            return {
                ...state,
                products,
                productListLoaded: true,
                galleryDetails: _omit(action.galleryDetails, ['facets']),
                sponsouredRenderedList: sponsouredIndex,
            };
        }

        case ECOREBATES_GET_SUCCESS: {
            const rebates = _get(action.rebatesInfo, 'EcoRebatesResponse.productRebateDetails', []);
            const availableRebates = [];
            rebates.map((rebateDetails) => {
                if (rebateDetails.rebatePrograms.length > 0) {
                    availableRebates.push(rebateDetails.product.sku);
                }
                return false;
            });
            if (availableRebates.length > 0) {
                return {
                    ...state,
                    products: state.products.map((list) => {
                        if (list.productList) {
                            return {
                                productList: list.productList.map((product) => {
                                    if (availableRebates.indexOf(product.skuId) !== -1) {
                                        return { ...product, ecoRebateAvailable: true };
                                    }
                                    return product;
                                }),
                            };
                        }
                        return list;
                    }),
                };
            }
            return state;
        }

        case MERGE_PRODUCTS: {
            const products = mergeProducts(
                action.itemsPerRow,
                state.products,
                action.criteoProducts
            );

            return {
                ...state,
                products,
            };
        }

        default:
            return state;
    }
}
