import ErrorPage from 'yoda-app-shell/lib/components/ErrorFullPage/ErrorFullPageLazy';
import React, { useRef, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { array, bool, object, oneOfType, string } from 'prop-types';
import { useLocation } from 'react-router-dom';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import { CanonincalFallback } from 'yoda-site-components/lib/components/SeoMetaTags/CanonicalFallback';
import { constructSEODefaultCanonicalUrl } from 'yoda-site-components/lib/helpers/Utils/seoHelper';
import LocalStorage from 'yoda-core-components/lib/helpers/LocalStorage/LocalStorage';
import CertonaConnected from '../Certona/CertonaLazy';
import FilterContent from '../FilterContent/FilterContent';
import FilterHeaderConnected from '../FilterHeader/FilterHeader';
import LoadPaginationConnected from '../Pagination/LoadPaginationLazy';
import NoGalleryResultsConnected from '../NoGalleryResults/NoGalleryResultsLazy';
import NoResultsCardConnected from '../NoResultsCard/NoResultsCard';
import NoSearchResults from '../NoSearchResults/NoSearchResultsLazy';
import ProductListConnected from '../Product/ProductList';
import SelectedFacetsConnected from '../SelectedFacets/SelectedFacets';
// import SuggestedFiltersConnected from '../SuggestedFilters/SuggestedFilters';
import selectNoSearchResults from '../../selectors/NoSearchResults';
import { getGalleryGridContentWithIris } from '../../actions/GalleryIrisAction';
import { updateUrl, showHorizonalFilters, getPageType } from '../../utils';
import ScrollRendererWrapper from '../ScrollRendererWrapper/ScrollRendererWrapper';

export const GalleryGrid = ({
    gridAds,
    irisComponents,
    irisComponentsWithoutGrid,
    irisPropsData,
    marketingSlot,
    isFromFailOverLayOut,
}) => {
    const { pathname = '', search = '' } = useLocation();
    const dispatch = useDispatch();
    const marketingSlotValue = useRef(marketingSlot);
    const state = useSelector((reduxState) => reduxState);
    const {
        context: {
            isPreview = false,
            featureFlags: {
                enableBrPreviewGallerySkeleton = false,
                enableMultipleThumbnails = false,
            } = {},
        } = {},
        multiSelectFilter: { enableProductsLoader = false } = {},
        failOverLayoutInfo: { isUpdateCanonicalUrl = true } = {},
        commonData: { showBRPreviewMock = false } = {},
        productList: { galleryDetails: { hasMultipleThumbnails = false } = {} } = {},
    } = state;
    const pagination = useSelector((state) => state.pagination.pagination);

    const renderBrPreviewSkeletonGrid = () => enableBrPreviewGallerySkeleton && showBRPreviewMock;

    const onPageChange = useCallback(
        (pageNumber, mktTiles, hasMarketingTiles) => {
            // TOFP2P-3609: CRITEO CHANGES add pagination to local storage
            LocalStorage.setData('paginationClick', true);

            const pageIncludesMultipleThumbnails =
                enableMultipleThumbnails && pagination?.multipleThumbNail;

            /* istanbul ignore next */
            if (pageIncludesMultipleThumbnails && pagination?.nextPageMktTiles > 0) {
                updateUrl(null, {
                    page: pageNumber,
                    mktTiles: pageNumber > 1 ? pagination?.nextPageMktTiles : null,
                });
            } else updateUrl(null, { page: pageNumber, mktTiles });
        },
        [pagination, hasMultipleThumbnails]
    );

    const displayMedaliaSurvey = useCallback(() => {
        const {
            context: {
                featureFlags: {
                    isDisplayPLPMedaliaSurvey = false,
                    isDisplaySRPMedaliaSurvey = false,
                } = {},
            } = {},
        } = state;
        const isSRP =
            (irisPropsData?.pageType === 'Search' || getPageType(pathname) === 's') &&
            isDisplaySRPMedaliaSurvey;
        // added !__SERVER_ condition on serverside srp div id is replacing with plp div id
        if ((isSRP || isDisplayPLPMedaliaSurvey) && !__SERVER__) {
            return (
                <div
                    id={isSRP ? 'srp_survey_inline' : 'plp_survey_inline'}
                    className={dt(['py-2', 'pl-2', 'relative', 'w-full'])}
                    data-automation-id={isSRP ? 'srp_survey_link' : 'plp_survey_link'}
                />
            );
        }
        return '';
    }, [irisPropsData, __SERVER__]);

    const renderErrorMessage = () => (
        <NoResultsCardConnected renderBrPreviewSkeletonGrid={renderBrPreviewSkeletonGrid()} />
    );

    const renderPagination = () => {
        return renderBrPreviewSkeletonGrid() ? (
            <div
                className={dt([
                    'sm:min-h-[45px]',
                    'bg-light-white',
                    'max-w-[425px]',
                    'w-full',
                    'border',
                    'm-auto',
                    'border-solid',
                    'border-gray-15',
                ])}
            />
        ) : (
            <LoadPaginationConnected onPageChange={onPageChange} />
        );
    };

    const getMarketingSlot = useCallback(() => {
        let marketingSlotData = [];

        if (marketingSlot?.content?.items) {
            marketingSlotData = marketingSlot?.content?.items?.filter((item) => item)?.slice(0, 2);
        }

        return marketingSlotData;
    }, [marketingSlot]);

    const setGalleyMarketingTilesData = useCallback(() => {
        const {
            context: {
                featureFlags: {
                    disableOrphanProducts = false,
                    enableVisualRelatedSearch = false,
                    enableDynamicVisNav = false,
                } = {},
            } = {},
        } = state;

        const marketingSlotForIris = getMarketingSlot();

        dispatch(
            getGalleryGridContentWithIris({
                marketingSlot: marketingSlotForIris,
                disableOrphanProducts,
                enableVisualRelatedSearch,
                enableDynamicVisNav,
            })
        );
    }, [marketingSlot]);

    const renderProductList = useCallback(() => {
        const productlistclass = enableProductsLoader ? dt(['relative']) : '';
        return (
            <div id="gallery-product-list" className={productlistclass}>
                <ProductListConnected
                    gridAds={gridAds}
                    irisComponents={irisComponents}
                    irisComponentsWithoutGrid={irisComponentsWithoutGrid}
                    irisPropsData={irisPropsData}
                    marketingSlot={getMarketingSlot()}
                    productList={{}}
                    renderBrPreviewSkeletonGrid={renderBrPreviewSkeletonGrid()}
                />
            </div>
        );
    }, [
        enableProductsLoader,
        gridAds,
        irisComponents,
        irisComponentsWithoutGrid,
        irisPropsData,
        showBRPreviewMock,
    ]);

    const constructCanonicalUrl = useCallback(() => {
        const {
            context: {
                featureFlags: { enableCanonicalFallback = false } = {},
                preferences: {
                    seoConfig: { excludeQueryParams = [], includeQueryParams = [] } = {},
                } = {},
            } = {},
        } = state;
        if (
            enableCanonicalFallback &&
            isFromFailOverLayOut &&
            isUpdateCanonicalUrl &&
            excludeQueryParams.length !== 0
        ) {
            let updatedPrams = '';
            const hostname = 'www.jcpenney.com';
            updatedPrams = constructSEODefaultCanonicalUrl(
                search,
                excludeQueryParams,
                includeQueryParams,
                false
            );
            return (
                <CanonincalFallback
                    urlLocation={pathname}
                    urlParams={updatedPrams}
                    hostname={hostname}
                />
            );
        }
        return '';
    }, [isFromFailOverLayOut, isUpdateCanonicalUrl, pathname]);

    const renderFilters = useCallback(
        (showHorizontal) =>
            showHorizontal ? (
                <SelectedFacetsConnected
                    wrapperClass={dt([
                        'align-center',
                        'flex',
                        'flex-no-wrap',
                        'mb-2',
                        'overflow-y-auto',
                        'whitespace-nowrap',
                    ])}
                />
            ) : (
                <FilterHeaderConnected
                    renderBrPreviewSkeletonGrid={renderBrPreviewSkeletonGrid()}
                />
            ),
        [showBRPreviewMock]
    );

    const getGalleryGridLayout = () => {
        const {
            statusCheck,
            context: {
                abTestEngagements = '',
                deviceType = {},
                deviceType: { isMobile },
                featureFlags: { enableStickyFacet = false } = {},
            } = {},
        } = state;
        const noResults = selectNoSearchResults(state);
        const isSearchPage = irisPropsData?.pageType === 'Search' || getPageType() === 's';
        const showHorizontal = showHorizonalFilters(abTestEngagements, deviceType);
        const enableStickyStyles = enableStickyFacet ? dt(['md:sticky', 'md:pl-[300px]']) : '';
        /*
         * __IS_MOBILE__ would be converted to props.deviceType.isMobile on server because of server's dynamic nature
         * so, we would need a Terser Plugin with mangle.properties options with property reserved=['props']
         * saying that it would mean that props constant would never be mangled allowing us to rely on a props constant in variable scope
         * which would be governed by our lint rule saying that if __IS_MOBILE__ is used there should be a props constant defined in scope
         *
         */
        /* istanbul ignore next */
        if (isSearchPage && statusCheck === 403) {
            return <ErrorPage templateType="404" />;
        }

        /* istanbul ignore next */
        if (noResults) {
            return (
                <ScrollRendererWrapper>
                    {isSearchPage ? <NoSearchResults /> : <NoGalleryResultsConnected />}
                    {!isSearchPage && displayMedaliaSurvey()}
                </ScrollRendererWrapper>
            );
        }

        const renderFiltersSection = (
            <>
                {/* <SuggestedFiltersConnected /> */}
                {isMobile ? (
                    renderFilters(showHorizontal)
                ) : (
                    <FilterHeaderConnected
                        renderBrPreviewSkeletonGrid={renderBrPreviewSkeletonGrid()}
                    />
                )}
            </>
        );

        const renderContentSection = (
            /* istanbul ignore next */
            <section
                className={`${dt([
                    'md:pr-0',
                    'lg:pl-1',
                    'md:min-w-0',
                    'md:flex-[3_0]',
                ])} ${enableStickyStyles} product-pane`}
                id="productlistheight"
            >
                {renderErrorMessage()}
                {renderProductList()}
                {renderPagination()}
            </section>
        );

        /* istanbul ignore next */
        return (
            <>
                {renderFiltersSection}
                {isMobile ? (
                    renderContentSection
                ) : (
                    <div className={dt(['flex', 'flex-row'])}>
                        <FilterContent
                            renderBrPreviewSkeletonGrid={renderBrPreviewSkeletonGrid()}
                        />
                        {renderContentSection}
                    </div>
                )}
                <ScrollRendererWrapper>
                    {displayMedaliaSurvey()}
                    <CertonaConnected page={isSearchPage ? 'searchRecZones' : 'galleryRecZones'} />
                </ScrollRendererWrapper>
            </>
        );
    };

    useEffect(() => {
        setGalleyMarketingTilesData();
    }, []);

    useEffect(() => {
        if (!(marketingSlot === marketingSlotValue?.current)) {
            setGalleyMarketingTilesData();
        }
    }, [marketingSlot]);

    let showContent = isPreview ? null : getGalleryGridLayout();
    if (!__SERVER__ && isPreview) showContent = getGalleryGridLayout();
    return (
        <>
            {constructCanonicalUrl()}
            {showContent}
        </>
    );
};

GalleryGrid.propTypes = {
    gridAds: oneOfType([object, string]),
    irisComponents: oneOfType([array, object]),
    irisComponentsWithoutGrid: oneOfType([array, object]),
    irisPropsData: oneOfType([array, object]),
    marketingSlot: oneOfType([array, object]),
    isFromFailOverLayOut: bool,
};

GalleryGrid.defaultProps = {
    gridAds: {},
    irisComponents: {},
    irisComponentsWithoutGrid: {},
    irisPropsData: {},
    marketingSlot: [],
    isFromFailOverLayOut: false,
};

export default GalleryGrid;
