import {
    all,
    call,
    delay,
    fork,
    put,
    race,
    select,
    takeLatest,
    takeMaybe,
    take,
} from 'redux-saga/effects';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import AnalyticsActionTypes from 'yoda-site-components/lib/actionTypes/AnalyticsActionTypes';
import { LOCATION_SERVICE_COOKIES_USER_PREFERRED_STORE } from 'yoda-site-components/lib/common/Constants';
import { SAVEDITEMS_GET_REQUEST } from 'yoda-site-components/lib/actionTypes/SavedItemActionTypes';
import {
    SET_USER_STORE_LOCATION_INFO,
    LOAD_USER_STORE_LOCATION_INFO_ERROR,
} from 'yoda-site-components/lib/actionTypes/LocationServiceActionTypes';
import { AB_TEST_DETAILS_SUCCESS } from 'yoda-site-components/lib/actionTypes/ABTestEngagementsActionTypes';
import {
    selectFeatureFlags,
    selectPreferences,
} from 'yoda-site-components/lib/selectors/ContextSelector';
import LocalStorage, {
    SessionStorage,
} from 'yoda-core-components/lib/helpers/LocalStorage/LocalStorage';
import { getABTestEngagements } from 'yoda-interfaces/lib/ABTestEngagements/ABTestApi';
import MboxHelper from 'yoda-interfaces/lib/helpers/MboxHelper';
import { getCookie } from 'yoda-interfaces/lib/helpers/Cookies';
import Cookies from 'yoda-core-components/lib/helpers/Cookies/Cookies';
import getUserAkamaiZipCode from 'yoda-site-components/lib/selectors/LocationSelector';
import { RERENDER_COMPONENT } from 'yoda-site-components/lib/actionTypes/GalleryRerenderActionTypes';
import { getGalleryDetailsById, getZone } from 'yoda-interfaces/lib/Catalog/CategoriesApi';
import isBloomReachAudience, {
    enableBloomReachPage,
} from 'yoda-site-components/lib/helpers/BloomReach/BloomReach';

import { getCriteoData, clearCriteoData } from 'yoda-site-components/lib/actions/CriteoAction';
import {
    triggerFormError,
    triggerOOSSponsoredProductFormError,
} from 'yoda-site-components/lib/actions/AnalyticsAction';
import { selectIsInitialCallClient } from '../selectors/IrisContentSelectors';
import {
    GET_FILTERS_SUCCESS,
    GET_GALLERY_DETAILS_BY_ID_ERROR,
    GET_GALLERY_DETAILS_BY_ID_REQUEST,
    GET_GALLERY_DETAILS_BY_ID_SUCCESS,
    GET_GALLERY_DETAILS_TIMEOUT_ERROR,
    GET_PRODUCT_LIST_SUCCESS,
    INITIATE_PENDING_ACTIONS,
    LAST_GALLERY_ACTION_RESULT,
    PREV_NO_RESULTS,
    RESET_COMMON_DATA,
    SPONSORED_DISPLAY_PAGE,
} from '../actionTypes/GalleryActionTypes';
import REFRESH_CONTENT from '../actionTypes/RefreshContentActionTypes';
import RERENDER_CERTONA from '../actionTypes/CertonaActionTypes';
import { HIDE_LOADER_GALLERY } from '../actionTypes/LoaderActionTypes';
import { ECOREBATES_GET_REQUEST } from '../actionTypes/EcoRebatesActionTypes';
import redirectRequired from '../helpers/RedirectHelper';
import Application from '../helpers/Application';
import {
    enableFilterAnalytics,
    getLastFilter,
    getPageType,
    getPageURL,
    getProductsPerPage,
    getURLParameterBykey,
    removeQueryStringParameter,
    updateUrl,
} from '../utils';
import { refreshStoreInventory } from './BOPISSaga';
import {
    exposeSkuSwatches,
    exposeEnsembleData,
    hasBreadCrumbLinks,
    getMultipleThumbnailsPpIds,
} from '../helpers/GalleryHelper';
import { selectContext } from '../selectors/ContextSelector';
import { scrollTitleIntoView } from '../utils/scrollTitleIntoView';
import getBreadCrumbInfo from '../selectors/BreadCrumbs';
import { getInteractiveVizNavFromIrisDataState } from '../selectors/InteractiveVizNav';
import { selectHideBreadCrumbsWhenNoNavigation } from '../selectors/fromState';
import mockBrPreviewData from './mockBrPreview';
import { updateSelectedStoreLocationInfo } from '../actions/BOPISActions';
import { allProductsCategory } from '../common/constants';

const logger = Application.getLogger('saga');

/**
 * Get the data from store to verify loader status
 * @param {Object}  state(Application state)
 */

let initialLoad = true;

export const getStoreContext = (state) => state.context;

export const getStoreProducts = (state) => state.products;
const getCriteo = (state) => state?.criteo;

const getFeatureFlags = (state) => selectFeatureFlags(state);

const getBOPISStore = (state) => state.locationServiceReducer;

const getIsInitialCallClient = (state) => selectIsInitialCallClient(state);

const getZipCode = (state) => getUserAkamaiZipCode(state);

const getZoneInfo = () => {
    const localStorage = LocalStorage && LocalStorage.getData('zone');
    if (localStorage) {
        return localStorage.zone;
    }
    return '';
};

const getZipInfo = () => {
    const localStorage = LocalStorage && LocalStorage.getData('zone');
    if (localStorage) {
        return localStorage.zipcode;
    }
    return '';
};

const shouldCallZoneAPI = (akamaiZipCode, zipcode) =>
    getPageType() === 's' && akamaiZipCode && (!zipcode || akamaiZipCode !== zipcode);

const productsParam = (numberOfProducts) => {
    let queryParam = null;
    if (numberOfProducts) {
        switch (numberOfProducts) {
            case 24:
                queryParam = null;
                break;

            case 48:
                queryParam = 'medium';
                break;

            case 72:
                queryParam = 'large';
                break;

            default:
                queryParam = null;
                break;
        }
    }
    return { productGridView: queryParam };
};

const getAtgDevIdParam = (action, passAtgDevId, preferences) => {
    const atgDevIdUsrSegValue = get(preferences, 'atgDevIdUsrSeg');
    const hasUsrSegValue =
        atgDevIdUsrSegValue && action.payload.params.UsrSeg === atgDevIdUsrSegValue;
    const isSearchPage = getPageType() === 's';
    const atgProfileId = getCookie('ACCOUNT_ID');
    const visitorId =
        !__SERVER__ && window && window.visitor && window.visitor.getMarketingCloudVisitorID();
    const hasId = !!(atgProfileId || visitorId);
    let atgDevId;

    if (hasUsrSegValue && passAtgDevId && isSearchPage && hasId) {
        if (atgProfileId && visitorId) {
            atgDevId = `atg_${atgProfileId} | dev_${visitorId}`;
        } else if (!atgProfileId && visitorId) {
            atgDevId = `dev_${visitorId}`;
        } else if (atgProfileId && !visitorId) {
            atgDevId = `atg_${atgProfileId}`;
        }

        return {
            atgDevId,
        };
    }

    return atgDevId;
};

/**
 * Update storeIds before making an API call if user is navigating from PDP page and has a userPreferredStore.
 */
function updateStorePDP() {
    const referrerUrl = LocalStorage.getData('galleryReferrer');
    // Comparing referrer URL and current URL to detect user has clicked on back button or not
    const fromPDP =
        referrerUrl &&
        removeQueryStringParameter(getPageURL(true), 'scrollTo') ===
            removeQueryStringParameter(referrerUrl, 'scrollTo');
    if (fromPDP) {
        LocalStorage.removeData('galleryReferrer');
        const userPreferredStore = Cookies.load(LOCATION_SERVICE_COOKIES_USER_PREFERRED_STORE);
        const userStoreDetails = userPreferredStore && JSON.parse(userPreferredStore);
        const storeIds = getURLParameterBykey('storeIds');
        const storeIdsList = (storeIds && storeIds.split('-')) || [];
        if (userStoreDetails && storeIdsList.indexOf(userStoreDetails.storeId) < 0) {
            storeIdsList.push(userStoreDetails.storeId);
            const updatedStoreIds = storeIdsList.join('-');
            const storeParams = {
                storeIds: updatedStoreIds,
                store_availability: 'same day pickup',
            };
            return storeParams;
        }
    }
    return {};
}

/**
 * update storeIds for same day pickup
 */
function updateSameDayPickUpStore(userStore) {
    const storeId = (userStore && userStore.storeId) || '';
    const isSameDayWithoutStoreId =
        storeId &&
        getURLParameterBykey('store_availability') === 'same day pickup' &&
        !getURLParameterBykey('storeIds');
    if (isSameDayWithoutStoreId) {
        return { storeIds: storeId };
    }
    return {};
}

function updateDefaultStore(params, userStore) {
    if (!__SERVER__) {
        const updatedParam = {
            ...updateSameDayPickUpStore(userStore),
            ...updateStorePDP(),
        };
        updateUrl(getPageURL(), { ...updatedParam }, true);
        return { ...params, ...updatedParam };
    }
    return params;
}

function* initiatePendingActions() {
    // These actions are the pending ones after server side rendering
    try {
        yield all([put({ type: SAVEDITEMS_GET_REQUEST }), put({ type: ECOREBATES_GET_REQUEST })]);
        enableFilterAnalytics();
    } catch (error) {
        yield put({ type: GET_GALLERY_DETAILS_BY_ID_ERROR, error });
    }
}

function* formatPayload() {
    const context = yield select(selectContext);
    const preferences = context.preferences || {};
    const abTestMboxName = get(preferences, 'abTest.mbox', 'yoda-userseg-mbox');
    const cleansedPayload = {
        abInput: {
            mbox: abTestMboxName,
        },
    };

    return cleansedPayload;
}

function* getABTestEngagementsSaga(apiPayload) {
    let usersegval = '';
    try {
        if (apiPayload) {
            const updatedapiPayload = yield call(formatPayload);
            let abTestEngagementsResponse = yield call(getABTestEngagements, updatedapiPayload);
            if (abTestEngagementsResponse && abTestEngagementsResponse.data) {
                abTestEngagementsResponse = get(abTestEngagementsResponse.data, 'testVersion', '');
                yield put({
                    type: AB_TEST_DETAILS_SUCCESS,
                    abTestEngagementsResponse,
                });
                const values =
                    abTestEngagementsResponse &&
                    abTestEngagementsResponse.indexOf('UsrSeg') !== -1 &&
                    abTestEngagementsResponse.split('=');
                usersegval = values && values.length > 1 ? values[1].trim() : ''; // get UsrSeg value from abTestEngagementsResponse
                const abTestPayload = {
                    UsrSeg: usersegval,
                };
                return abTestPayload;
            }
        }
    } catch (error) {
        yield put({ type: 'AB_TEST_ENGAGEMENTS_ERROR', error });
    }
    return {};
}

function* galleryScrollHandler() {
    const state = yield select((state) => state);
    const interactiveVizNav = getInteractiveVizNavFromIrisDataState(state);
    const breadCrumbInfo = getBreadCrumbInfo(state);
    const deviceType = get(state, 'context.deviceType', {});
    const hideBreadCrumbsWhenNoNavigation = selectHideBreadCrumbsWhenNoNavigation(state);
    const { filterName = '' } = getLastFilter() || {};
    const isNative = get(state, 'context.isNative', false);
    const paginationClickBack = LocalStorage.getData('paginationClickBack');

    if (filterName === 'same day pickup' || !isEmpty(interactiveVizNav)) {
        scrollTitleIntoView('#gallery-product-list', deviceType?.isDesktop, isNative);
    } else if (document.getElementById('inspirational-block')) {
        scrollTitleIntoView('#inspirational-block', deviceType?.isDesktop, isNative);
    } else if (paginationClickBack) {
        scrollTitleIntoView('#gallery-pagination', deviceType?.isDesktop, isNative, true);
        LocalStorage.removeData('paginationClickBack');
        LocalStorage.removeData('browserBFButtonClick');
    } else if (
        !isNative &&
        hasBreadCrumbLinks(breadCrumbInfo, deviceType, hideBreadCrumbsWhenNoNavigation)
    ) {
        scrollTitleIntoView(
            '[data-automation-id="product-breadcrumbs"',
            deviceType?.isDesktop,
            isNative
        );
    } else {
        scrollTitleIntoView('#gallery-title', deviceType?.isDesktop, isNative);
    }
}

function* galleryDetailsSaga(action) {
    try {
        yield all([
            put({ type: RESET_COMMON_DATA, initialLoad: action.payload.initialLoad }),
            // Disabling as part of TOFP2P-757
            // put({ type: SHOW_LOADER_GALLERY }),
            put({ type: REFRESH_CONTENT, refreshContent: false }),
        ]);
        const { passAtgDevId, enableBrPreviewGallerySkeleton } = yield select(getFeatureFlags);
        const storeContext = yield select(getStoreContext);
        const productsList = yield select(getStoreProducts);
        // eslint-disable-next-line no-unused-vars
        const isInitialIrisCallClient = yield select(getIsInitialCallClient);
        const scrollTo = getURLParameterBykey('scrollTo');
        scrollTo && LocalStorage.setData('galleryScrollTo', scrollTo);

        // get preferences data
        const context = yield select(selectContext);
        const preferences = context.preferences || {};
        const {
            enableXGNSCanonicalRedirect: enableXGNSRedirect,
            disableOrphanProducts,
            enableVisualRelatedSearch,
            enableDynamicVisNav,
            enableSSRForPLP,
            enableBloomReach = false,
            enableBloomReachV3PLP = true,
            enableBloomReachNative = false,
            enableBrStoreNullResultsMessage = true,
            enableFindaStoreNullResults = true,
            enableCriteo = false,
            enableSponsouredProduct = false,
            enableSponsoredProductsOverflow = false,
            enableSponsoredProductNative = true,
            enableMultipleThumbnails = false,
        } = yield select(getFeatureFlags);
        const isNative = context.isNative || false;
        const isNativeBloomReach = enableBloomReachNative ? false : isNative;
        const isbloomReachAudience =
            isBloomReachAudience(enableBloomReach, isNativeBloomReach) && getPageType() === 's';
        const { requestUrl = '' } = context;
        const {
            plpBloomreachInclusionList = [],
            sponsoredProductsInclusionList = [],
            sponsoredProductsEnableFilters = [],
        } = preferences;
        const isBloomReachV3Page = enableBloomReachPage(
            enableBloomReachV3PLP,
            requestUrl,
            plpBloomreachInclusionList
        );

        // BR use store in url to set store when landing on no results page. handle back button with store changes
        const fromNoResultsPage = !!LocalStorage.getData('NoResultsPage');
        const { storeIds } = action.payload.params;
        const userStore = yield select(getBOPISStore);
        const BrNoResultsWithStoreId = !!(
            fromNoResultsPage &&
            (isBloomReachV3Page || isbloomReachAudience) &&
            storeIds &&
            storeIds !== userStore?.storeId
        );

        const storesIdFromUrl = getURLParameterBykey('storeIds');

        if (BrNoResultsWithStoreId) {
            yield put(
                updateSelectedStoreLocationInfo({
                    selectedStoreId: storeIds,
                })
            );
        }

        const apiParams =
            action.payload.initialLoad && !BrNoResultsWithStoreId
                ? updateDefaultStore(action.payload.params, userStore)
                : action.payload.params;

        // TOFP2P-3676 criteo call changed to be here so we get storeId appended to params if they are sent to SOLR as well
        if (enableCriteo) {
            const enableFilters = sponsoredProductsEnableFilters?.includes(action.payload.pageType);
            const sortClicked = enableFilters
                ? !action.payload.initialLoad && LocalStorage.getData('sortClicked')
                : true;

            const paginationClick =
                !action.payload.initialLoad &&
                (LocalStorage.getData('paginationClick') ||
                    LocalStorage.getData('paginationClickBack'));

            if (action.payload.initialLoad || paginationClick || !sortClicked) {
                LocalStorage.removeData('paginationClick');

                // new code to set id for brand pages with id
                let criteoParams = apiParams;
                if (
                    action.payload.pageType === 'Gallery' &&
                    !criteoParams?.id &&
                    action.payload?.apiPath === 'g/brand'
                ) {
                    criteoParams = { ...apiParams, id: allProductsCategory };
                }

                const isNativeSponsoredProduct =
                    isNative && enableSponsoredProductNative ? true : !isNative;

                const isSponsoredProductEnabled =
                    enableSponsouredProduct &&
                    sponsoredProductsInclusionList.includes(action.payload.pageType) &&
                    !action.payload.params?.page &&
                    isNativeSponsoredProduct;

                yield put({
                    type: SPONSORED_DISPLAY_PAGE,
                    sponsoredDisplayPage: isSponsoredProductEnabled,
                });

                yield put(
                    getCriteoData({
                        pageType: action.payload.pageType,
                        params: criteoParams,
                        waitForCriteo: isSponsoredProductEnabled,
                    })
                );
            } else {
                LocalStorage.removeData('sortClicked');
                yield put(clearCriteoData());
                yield put({
                    type: SPONSORED_DISPLAY_PAGE,
                    sponsoredDisplayPage: false,
                });
            }
        }
        // Failing on Server Side Will re-initiate the call on client side.
        const timeout = storeContext.isPreview ? 45000 : __SERVER__ ? 50000 : 20000; //eslint-disable-line

        // Get the UsrSeq value and send it to Solr for AB Testing.
        let galleryparams = action.payload.params || {};

        delete action.payload.params.atgdevid; // eslint-disable-line no-param-reassign
        delete apiParams.atgdevid;

        // TOFP2P-2349: mock data for br preview
        let galleryDetails;
        const {
            plpSwitchWidget: { plpSwitchWidgetInclusionList = [] } = {},
            plpBloomreachV3PreviewInclusionList = [],
            sponsouredProductSlotConfig,
        } = preferences;
        const pageType = getPageType();
        const enableMockDataBloomreachInclusionList = plpSwitchWidgetInclusionList.includes(
            `/${pageType}`
        );
        const enableBrV3PreviewPageIncluded = plpBloomreachV3PreviewInclusionList.includes(
            `/${pageType}`
        );
        const { isPreview = false } = context;
        const { switchWidgetValue = 'Solr', enableBloomReachV3Preview } = action.payload;
        const viewingBrPreview =
            enableBrPreviewGallerySkeleton &&
            isPreview &&
            switchWidgetValue === 'Bloomreach' &&
            enableMockDataBloomreachInclusionList;
        if (
            viewingBrPreview &&
            (!enableBloomReachV3Preview ||
                (enableBloomReachV3Preview && !enableBrV3PreviewPageIncluded))
        ) {
            galleryDetails = mockBrPreviewData;
        } else {
            const apiCall = yield race({
                galleryDetails: call(
                    getGalleryDetailsById,
                    action,
                    {
                        ...productsParam(getProductsPerPage(storeContext.preferences)),
                        ...action.payload.params,
                        ...galleryparams,
                        ...apiParams,
                        responseType: 'organic',
                        ...getAtgDevIdParam(action, passAtgDevId, preferences),
                    },
                    storeContext
                ),
                timeout: delay(timeout),
            });

            galleryDetails = apiCall?.galleryDetails;

            if (enableMultipleThumbnails && galleryDetails?.data?.organicZoneInfo?.products) {
                const splitPPIds = getMultipleThumbnailsPpIds(
                    galleryDetails.data.organicZoneInfo.products
                );
                galleryDetails.data.hasMultipleThumbnails =
                    splitPPIds !== 'n/a' && splitPPIds.length > 0;
                galleryDetails.data.splitPPIds = splitPPIds;
            } else {
                galleryDetails.data.hasMultipleThumbnails = false;
            }

            if (galleryDetails?.data?.brPrvIgnore) galleryDetails = mockBrPreviewData;
        }

        // Debugger for Serverside data.
        if (galleryDetails) {
            const actions = [];
            if (
                enableBrStoreNullResultsMessage &&
                (isBloomReachV3Page || isbloomReachAudience) &&
                storesIdFromUrl
            ) {
                const galleryData = get(galleryDetails, 'data.organicZoneInfo.products', []);
                const productsList = yield select(getStoreProducts);
                if (
                    (galleryData.length === 0 && productsList.length !== 0) ||
                    (enableFindaStoreNullResults &&
                        galleryData.length === 0 &&
                        productsList.length === 0)
                ) {
                    const removeStoreParams = (url) => {
                        const ignoredParams = ['storeIds', 'store_availability', 'activeFacetId'];
                        let updatedUrl = url;

                        ignoredParams.forEach((ignoredParam) => {
                            updatedUrl = removeQueryStringParameter(updatedUrl, ignoredParam);
                        });

                        return updatedUrl;
                    };

                    const removeStoreID = removeStoreParams(location.pathname + location.search);
                    LocalStorage.setData('galleryNoResults', true);

                    history.pushState({}, '', removeStoreID);
                    window.location.reload();
                    return;
                }
            }
            if (__SERVER__ && enableSSRForPLP) {
                const { headers: { _headers: headers = {} } = {} } = galleryDetails;
                const responseControl = headers['cache-control'];
                if (responseControl) {
                    const [cacheControl] = responseControl;
                    const [ttl] = cacheControl.split('=');
                    if (ttl) {
                        yield put({
                            type: 'SET_SOLR_CACHE',
                            cache: { ttl },
                        });
                    }
                }
            }
            const redirect = redirectRequired(
                galleryDetails.data,
                action.payload.loc.pathname,
                enableXGNSRedirect
            );
            const galleryData = get(galleryDetails, 'data', {});
            const gallerySearchStatus = get(galleryDetails, 'status', 200);
            if (__SERVER__ && redirect) {
                yield put({
                    type: 'SET_REDIRECT_URL',
                    redirectData: redirect,
                });
            }

            actions.push(put({ type: HIDE_LOADER_GALLERY }));
            // Invoke gallery actions only if redirect is false
            if (!redirect) {
                if (productsList.length) {
                    actions.push(
                        put({
                            type: RERENDER_CERTONA,
                            payload: { reRenderCertona: true },
                        })
                    );
                }
                // https://jira.jcpenney.com/browse/MNPDPYODA-9656 IRIS migration | H 1 scroll to gallery title after pagination is not working
                if (!action.payload.initialLoad) {
                    actions.push(
                        put({
                            type: RERENDER_COMPONENT,
                            payload: true,
                        })
                    );
                }
                if (gallerySearchStatus === 403) {
                    // refatcor needed need to work without passing gallerydetail obj
                    actions.push(
                        put({
                            type: GET_GALLERY_DETAILS_BY_ID_SUCCESS,
                            galleryStatus: gallerySearchStatus,
                            galleryDetails: {},
                            deviceType: storeContext.deviceType,
                        })
                    );
                } else {
                    actions.push(
                        put({
                            type: GET_GALLERY_DETAILS_BY_ID_SUCCESS,
                            galleryDetails: galleryData,
                            deviceType: storeContext.deviceType,
                            brPreviewMessage: storeContext?.messagesTexts?.bloomReachPreviewMessage,
                            productsPerPage: getProductsPerPage(storeContext.preferences),
                        })
                    );

                    // TOFP2P-3524 - Changes to fix TOFP2P-3425 caused major lag so we are ok with reverting back.
                    const backBtnClick = LocalStorage.getData('browserBFButtonClick');
                    const paginationClickBack = !!LocalStorage.getData('paginationClickBack');
                    if (!action.payload.initialLoad && (!backBtnClick || paginationClickBack)) {
                        yield call(galleryScrollHandler);
                    } else {
                        LocalStorage.removeData('galleryMarketingScrollTo');
                        LocalStorage.removeData('fromGallery');
                        LocalStorage.removeData('browserBFButtonClick');
                        LocalStorage.removeData('NoResultsPage');
                    }
                    actions.push(
                        put({
                            type: LAST_GALLERY_ACTION_RESULT,
                            galleryDetails: galleryData,
                            lastAction: action,
                        })
                    );

                    if (action.payload.AMPpage || __SERVER__) {
                        actions.push(
                            put({
                                type: GET_PRODUCT_LIST_SUCCESS,
                                galleryDetails: galleryData,
                                deviceType: storeContext.deviceType,
                                productsPerPage: getProductsPerPage(storeContext.preferences),
                                disableOrphanProducts,
                                enableVisualRelatedSearch,
                                enableDynamicVisNav,
                                pageType: getPageType(),
                            })
                        );
                        actions.push(
                            put({
                                type: GET_FILTERS_SUCCESS,
                                galleryDetails: galleryData,
                                isbloomReachAudience,
                                isBloomReachV3Page,
                            })
                        );
                    } else if (!__SERVER__) {
                        // expose sku swatches globally for A/B Testing
                        exposeSkuSwatches(galleryData);
                        // expose ensemble products info for A/B Testing
                        exposeEnsembleData(galleryData);

                        let criteo = yield select(getCriteo);
                        let sponsouredgalleryData = criteo?.sponsouredProduct?.data;
                        const waitForCriteo = criteo?.waitForCriteo;
                        if (enableSponsouredProduct && waitForCriteo) {
                            const sponsoredProductTimeout =
                                storeContext?.preferences?.sponsoredProductTimeout || 15000; // 15 seconds timeout
                            const { success, timeout } = yield race({
                                success: take('SPONSOURED_GET_RESULTS'),
                                timeout: delay(sponsoredProductTimeout), // 5 seconds timeout
                            });

                            criteo = yield select(getCriteo);
                            sponsouredgalleryData = success ? criteo?.sponsouredProduct?.data : {};
                            if (timeout) {
                                yield put(
                                    triggerFormError([
                                        {
                                            errorDescription: `criteo ad - aggregator call timeout error`,
                                        },
                                    ])
                                );
                            }
                        }
                        if (
                            enableSponsouredProduct &&
                            sponsouredgalleryData?.OOSProducts?.length > 0
                        ) {
                            const OOSProducts = sponsouredgalleryData.OOSProducts.filter(
                                (product) => product?.ppActive && !product?.inStock
                            ).map((item) => ({
                                errorDescription: `criteo ad - browse api error - PPID:${item?.ParentSKU}:out of stock`,
                            }));

                            if (OOSProducts.length > 0) {
                                yield put(triggerOOSSponsoredProductFormError(OOSProducts));
                            }
                        }

                        actions.push(
                            put({
                                type: GET_PRODUCT_LIST_SUCCESS,
                                galleryDetails: galleryData,
                                deviceType: storeContext.deviceType,
                                productsPerPage: getProductsPerPage(storeContext.preferences),
                                disableOrphanProducts,
                                enableVisualRelatedSearch,
                                enableDynamicVisNav,
                                pageType: getPageType(),
                                sponsouredgalleryData,
                                sponsouredProductSlotConfig,
                                enableSponsouredProduct,
                                enableSponsoredProductsOverflow,
                                enableMultipleThumbnails,
                                isNative,
                            })
                        );
                        actions.push(
                            put({
                                type: GET_FILTERS_SUCCESS,
                                galleryDetails: galleryData,
                                isbloomReachAudience,
                                isBloomReachV3Page,
                            })
                        );
                    }
                }
                // Yield here.
                yield all(actions);

                yield put({
                    type: PREV_NO_RESULTS,
                    galleryDetails: galleryData,
                });

                yield call(refreshStoreInventory);

                if (!__SERVER__) {
                    yield fork(initiatePendingActions, {
                        payload: {
                            location: action.payload.loc,
                        },
                    });
                }
            }
        } else if (!__SERVER__) {
            logger.error({
                errorMessage: 'GalleryPage API timeout',
                exception: 'Timeout Error',
            });
            const errorDetails = [{ errorDescription: 'gallery Service timeout' }];
            yield all([
                put({ type: AnalyticsActionTypes.FORM_ERROR, errorDetails }),
                put({ type: GET_GALLERY_DETAILS_TIMEOUT_ERROR }),
                put({ type: HIDE_LOADER_GALLERY }),
            ]);
        } else if (__SERVER__) {
            logger.error({
                errorMessage: 'GalleryPage API timeout',
                exception: 'Timeout Error',
            });
        }

        const enableABUserSegLogicFF = get(context.featureFlags, 'enableABUserSegLogic', false);

        if (enableABUserSegLogicFF && !isEmpty(action.payload.abTestParams)) {
            let cachedData = '';
            let usrsegStorageKey = '';
            let usersegval = '';
            // Get the UsrSeq value to invoke Endeca or Solr.
            const abtesttimeout = get(preferences, 'abTest.apiTimeOut', 5000); //eslint-disable-line
            const mbox = MboxHelper.getMboxValuesFromCookie();
            const mboxsession = mbox && mbox.session;
            // Getting the data which is cached and is in the local Storage. We need to pass the preferences object for this, which contains the cacheMinutes i.e. how long we need to store the object in localStorage, and flushTime - UTC milliseconds.
            cachedData = LocalStorage.getData('abTestUsrSeg', true);
            usersegval = cachedData && cachedData.createdTS ? '' : cachedData;
            // userseg will be stored part of local storage as a  {key: mboxsession , val: usersegval} format
            // If nothing is returned, make the service call and store in the localStorage.
            const parsedJson = usersegval && JSON.parse(usersegval);
            usersegval =
                parsedJson && parsedJson.val && parsedJson.key === mboxsession
                    ? parsedJson.val
                    : '';

            if (!usersegval) {
                const userSegResp = yield race({
                    usersegval: call(getABTestEngagementsSaga, {
                        apiPayload: action.payload.abTestParams,
                    }),
                    timeout: delay(abtesttimeout),
                });
                usersegval = userSegResp.usersegval && userSegResp.usersegval.UsrSeg;
                usrsegStorageKey = { key: mboxsession, val: usersegval };
                usrsegStorageKey &&
                    LocalStorage.setData('abTestUsrSeg', usrsegStorageKey, true, '', false); // userseg localstorage expiration should be updated as per mbox unique identifier..inprogress
            }
            galleryparams = usersegval
                ? { ...action.payload.params, UsrSeg: usersegval }
                : action.payload.params;
        }
        if (enableBrStoreNullResultsMessage && LocalStorage.getData('galleryNoResults')) {
            const { storeNullResultsMessage = '' } = preferences;
            yield put({
                type: 'GET_STORE_NULL_RESULT_PAGE',
                noStores: storeNullResultsMessage,
            });

            LocalStorage.removeData('galleryNoResults');
        }
    } catch (error) {
        logger.error({
            errorMessage: 'GalleryPage load API call failed',
            exception: error,
        });
        if (!__SERVER__) {
            yield all([
                put({ type: GET_GALLERY_DETAILS_BY_ID_ERROR, error }),
                put({ type: HIDE_LOADER_GALLERY }),
            ]);
        }
    }
    const { enableBrStoreNullResultsMessage = true } = yield select(getFeatureFlags);
    if (enableBrStoreNullResultsMessage && LocalStorage.getData('galleryNoResults')) {
        const { storeNullResultsMessage = '' } = preferences;
        yield put({
            type: 'GET_STORE_NULL_RESULT_PAGE',
            noStores: storeNullResultsMessage,
        });

        LocalStorage.removeData('galleryNoResults');
    }
}

function* getGalleryDetails({ payload }) {
    const {
        enableRealTimeInventory,
        enableLocationService,
        disableZoneInfoSevice,
        enablePLPSwitchWidget = false,
        enableBloomReachV3PLP = false,
        enableBloomReachV3Preview = false,
    } = yield select(getFeatureFlags);

    const {
        plpSwitchWidget: {
            plpSwitchWidgetDefaultValue = 'Solr',
            srpSwitchWidgetDefaultValue = 'Bloomreach',
        } = {},
        timeOutDelayForStoreLocation = 200,
    } = yield select(selectPreferences);
    const context = yield select(selectContext);
    const preferences = context.preferences || {};
    const { requestUrl = '' } = context;
    const { plpBloomreachInclusionList = [] } = preferences;
    const isBloomReachV3Page = enableBloomReachPage(
        enableBloomReachV3PLP,
        requestUrl,
        plpBloomreachInclusionList
    );

    const pageType = getPageType();
    const switchWidgetKey = pageType === 'g' ? 'plpSwitchWidget' : 'srpSwitchWidget';
    const selectedValue = SessionStorage.getData(`${switchWidgetKey}SelectedValue`, true);
    let switchWidgetValue =
        pageType === 'g' ? plpSwitchWidgetDefaultValue : srpSwitchWidgetDefaultValue;
    switchWidgetValue = selectedValue || switchWidgetValue;
    const updatedPayload = !enablePLPSwitchWidget
        ? { ...payload, isBloomReachV3Page }
        : {
              ...payload,
              switchWidgetValue,
              enablePLPSwitchWidget,
              enableBloomReachV3Preview,
              isBloomReachV3Page,
          };
    // akamai will return the store details from location services
    // akamai caches the results which we don't want on the server side
    // location services are enabled only on client side for this reason
    // send store id on initial load when enableLocationService is enabled
    if (enableRealTimeInventory && enableLocationService) {
        let storeDetails = null;
        let userStore = yield select(getBOPISStore);
        const zipcode = yield select(getZipCode);
        const sessionZip = getZipInfo();
        const noResultsPage = LocalStorage.getData('NoResultsPage');
        if (userStore && userStore.storeId) {
            storeDetails = userStore;
        }

        if (initialLoad && !storeDetails) {
            userStore = yield race({
                success: takeMaybe(SET_USER_STORE_LOCATION_INFO),
                error: takeMaybe(LOAD_USER_STORE_LOCATION_INFO_ERROR),
                timeout: delay(timeOutDelayForStoreLocation),
            });

            if (userStore.success) {
                storeDetails = userStore.success.data;
            } else if (!disableZoneInfoSevice && shouldCallZoneAPI(zipcode, sessionZip)) {
                // retrive zone info
                const zoneInfo = yield race({
                    success: call(getZone, { zipcode }),
                    timeout: delay(timeOutDelayForStoreLocation),
                });
                if (zoneInfo && zoneInfo.success) {
                    const zone = get(zoneInfo, 'success.data.zoneInfo.zone', '');
                    LocalStorage.setData('zone', { zipcode, zone });
                }
            }
        }

        // send store id which is saved in global var
        // Sending geoLocStoreId with every SOLR API request.
        const storeId = storeDetails && storeDetails.storeId;
        const zoneIformation = getZoneInfo();
        if (storeId) {
            if (
                updatedPayload?.params?.storeIds &&
                updatedPayload.params.storeIds !== storeId &&
                !noResultsPage
            ) {
                if (!__SERVER__ && !document.getElementById('storeGalleryBopisFilter')) {
                    updatedPayload.params.storeIds = storeId;
                }
            }
        } else if (zoneIformation && getPageType() === 's' && !disableZoneInfoSevice) {
            updatedPayload.params.Userzoneid = zoneIformation;
        }

        initialLoad = false;
    }
    yield fork(galleryDetailsSaga, { payload: updatedPayload });
}

const watchInitiatePendingActionsRequest = function* watchInitiatePendingActionsRequest() {
    yield takeLatest(INITIATE_PENDING_ACTIONS, initiatePendingActions);
};
watchInitiatePendingActionsRequest.sagaName = 'watchInitiatePendingActionsRequest';

const watchGalleryDetailsRequest = function* watchGalleryDetailsRequest() {
    yield takeLatest(GET_GALLERY_DETAILS_BY_ID_REQUEST, getGalleryDetails);
};
watchGalleryDetailsRequest.sagaName = 'watchGalleryDetailsRequest';

export default watchGalleryDetailsRequest;
export { watchInitiatePendingActionsRequest };
