import React, { useRef, useCallback, useMemo, useState, useEffect } from 'react';
import { bool, number, object, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import LocalStorage from 'yoda-core-components/lib/helpers/LocalStorage/LocalStorage';
import ProductCard from 'yoda-site-components/lib/components/ProductCard/ProductCard';
import { findMobileOS } from 'yoda-core-components/lib/helpers/Utils/Utils';
import { withRouter } from 'react-router';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import { isDesktopWidth } from 'yoda-core-components/lib/helpers/Utils/mediaQuery';
import get from 'lodash/get';
import { setOnClickBeaconStatus } from 'yoda-site-components/lib/actions/CriteoAction';
import useGalleryProductCardEffect from './useGalleryProductCardEffect';

import {
    getPriceFormatted,
    getScene7desktopImageURL,
    getScene7ImageURL,
    getStoreSeq,
    getURLParameterBykey,
    goUrl,
    removeQueryStringParameter,
    updateQueryStringParameter,
    getPageURL,
} from '../../utils';
import { searchResultClick, videoPlayerAnalytics } from '../../actions/AnalyticsActions';
import badgesConfig from './ProductBadgesConfig';
import { source } from '../../common/constants';

const defaultOffsetValue = 1000;
let invokeAddToCart;

export const getBadgeThemeData = (
    selectedBadge,
    upperBadge,
    hideTopLeftBadge,
    hideBottomBadge,
    enableProductTitlePrefix,
    skuSwatch,
    bottomBadges
) => {
    let badge = {};
    if (selectedBadge) {
        // eslint-disable-next-line no-param-reassign
        selectedBadge.text = selectedBadge.name ? selectedBadge.name.toLowerCase() : '';
        if ((!hideTopLeftBadge && upperBadge) || enableProductTitlePrefix) {
            if (selectedBadge.text.includes('save up to')) {
                // Bundles Badge
                badge = { ...badgesConfig.bundle, text: selectedBadge.text };
            } else {
                badge = badgesConfig[selectedBadge.text.replace(/[-\s]/g, '')] || {
                    ...badgesConfig.default,
                    text: selectedBadge.name,
                };
            }
        } else if (!hideBottomBadge && !upperBadge) {
            // Bottom badge logic
            if (isDesktopWidth() || selectedBadge.urlParam === 'collection') {
                badge.text = selectedBadge.text;
            } else {
                // Applies only for Small and Medium
                const moreColorsAndSizes = [];
                if (skuSwatch?.length > 1) {
                    moreColorsAndSizes.push('Colors');
                }
                if (bottomBadges) {
                    moreColorsAndSizes.push('Sizes');
                }
                badge.text = moreColorsAndSizes.length
                    ? `More ${moreColorsAndSizes.join(' & ')}`
                    : '';
            }
        }
    }
    return badge;
};

export const setLocalStorageData = (
    key,
    value,
    storeWithKey,
    domain,
    fallbackEnabled,
    expiryInMins
) => {
    try {
        LocalStorage.setData(key, value, storeWithKey, domain, fallbackEnabled, expiryInMins);
    } catch (error) {
        /* istanbul ignore next */
        console.error(`Failed to set data in LocalStorage: ${error}`);
    }
};

export const setDefOffSettoLocalStorage = (ppId, isNative, isMobileOS) => {
    const topOfElement = !__SERVER__ ? window.pageYOffset : defaultOffsetValue;
    setLocalStorageData('galleryScrollToOffSet', topOfElement);
    if (!__SERVER__ && isNative && isMobileOS === 'iOS') {
        const scrollKeyVal = {
            galleryScrollTo: ppId,
            galleryScrollToOffSet: topOfElement,
        };
        const scrollKey = { key: window.location.href, val: scrollKeyVal };
        scrollKeyVal &&
            setLocalStorageData('nativeGalleryScrollToOffSet', scrollKey, true, '', false);
    }
};

export const getOffSetValue = () => {
    const topOfElement = LocalStorage.getData('galleryScrollToOffSet');
    return topOfElement || defaultOffsetValue;
};

export const ConditionalWrap = ({ productWrap, children }) => productWrap(children);

export const GalleryProductCard = ({
    productDetails,
    cardType,
    fromProductList,
    index,
    router,
}) => {
    const dispatch = useDispatch();
    const cardRefDesktop = useRef(null);
    const cardRefMob = useRef(null);
    const [isHandleProductClick, setHandleProductClick] = useState(false);
    const [isHandleProductImageClick, setHandleProductImageClick] = useState(false);

    const state = useSelector((reduxState) => reduxState);
    const {
        context: {
            isNative = false,
            featureFlags: {
                enableMiniPDPFeature = true,
                enableProductTitlePrefix = true,
                enableReactRenderingStrategy = false,
                hideTopLeftBadge = true,
                hideBottomBadge = true,
                displayScene7PictureComponent = true,
                displayS7PictureComponentAltImg = true,
                highlightBoostedPPIds = false,
            } = {},
            preferences: { fpac: { gallery = {} } = {} } = {},
        } = {},
    } = state;
    const criteoData = useSelector((state) => get(state, 'criteo', {}));
    const { onClickBeaconCalledPPIDs } = criteoData;
    const pricingConfig = gallery;

    const {
        imagesInfo,
        activeBottomBadge,
        activeUpperBadge,
        availabilityStatus,
        averageRating,
        bulletedCopyAttrs,
        bundleIndicator,
        compareIn,
        ecoRebateAvailable,
        eligibilityMessage,
        enableMiniPDP,
        name,
        pdpUrl,
        reviewCount,
        skuId,
        videoId,
        ppId,
        skuSwatch,
        bottomBadges,
        entityType,
        // Pricing Details
        originalPriceLabel,
        currentPriceLabel,
        originalMax,
        originalMin,
        currentMax,
        currentMin,
        minSavePrice,
        maxSavePrice,
        mapPrice,
        marketingLabel,
        promotionMessages,
        fpacPriceMax,
        fpacPriceMin,
        fpacCoupon,
        brand,
        subDivision,
        sponsoredProduct,
        OnBasketChangeBeacon = '',
        OnClickBeacon = '',
        OnViewBeacon = '',
        OnWishlistBeacon = '',
    } = productDetails;

    const bottomBadgeDetails = activeBottomBadge || (skuSwatch?.length ? {} : null);

    const getDefaultOffSetValue = useCallback(() => getOffSetValue(), []);

    useGalleryProductCardEffect(
        findMobileOS(),
        isDesktopWidth(),
        sponsoredProduct ? `${skuId}-sp` : skuId,
        cardRefDesktop,
        getDefaultOffSetValue
    );

    useEffect(() => {
        if (
            (isHandleProductClick || isHandleProductImageClick) &&
            OnClickBeacon &&
            !onClickBeaconCalledPPIDs?.includes(skuId)
        ) {
            navigator.sendBeacon(`https:${OnClickBeacon}`);
            dispatch(setOnClickBeaconStatus(skuId));
            if (isHandleProductClick) setHandleProductClick(false);
            if (isHandleProductImageClick) setHandleProductImageClick(false);
        }
    }, [isHandleProductClick, isHandleProductImageClick]);

    const productHandler = (e, fromProductList, prodInfo, from) => {
        e.stopPropagation();
        // Prerequisites before navigating to the PDP page goes here
        if (fromProductList) {
            // Fire analytics
            const payload = {
                sbResultsInfo: {
                    resultPosition: prodInfo.index,
                    product: [
                        {
                            productInfo: {
                                productPPID: prodInfo.ppId,
                            },
                        },
                    ],
                },
            };
            dispatch(searchResultClick(payload));
        }
        // Initimation to PDP that user has come through gallery page to enable history.back on gallery breadcrumb
        setLocalStorageData('fromGallery', { scrollTo: from });
    };

    const triggerAnalytics = useCallback(
        (pageId) => {
            const videoPlayerData = window.videojs(pageId);
            const product = [{ productInfo: { productPPID: ppId } }];
            dispatch(videoPlayerAnalytics({ videoPlayerData, product }));
        },
        [ppId]
    );

    const setDefaultOffSetIntoLocalStorage = useCallback(
        () => setDefOffSettoLocalStorage(skuId, isNative, findMobileOS()),
        [skuId, isNative]
    );

    const onProductClickEvent = useCallback(
        (e, from) => {
            setLocalStorageData('galleryScrollTo', sponsoredProduct ? `${skuId}-sp` : skuId);
            setDefaultOffSetIntoLocalStorage();
            // To send the sorted list of stores based on distance if user has userPreferredStore and navigating back from PDP.
            const storeSeq = getStoreSeq();
            if (storeSeq) {
                e.currentTarget.href = updateQueryStringParameter(
                    e.currentTarget.href,
                    'storeIds',
                    storeSeq
                );
            }
            setHandleProductClick(true);
            productHandler(e, fromProductList, { index, pdpUrl, ppId }, from);
        },
        [skuId, ppId, pdpUrl, index]
    );

    const onImageClickEvent = useCallback(
        (e, from = 'image') => {
            setHandleProductImageClick(true);
            setLocalStorageData('galleryScrollTo', sponsoredProduct ? `${skuId}-sp` : skuId);
            setDefaultOffSetIntoLocalStorage(skuId);
            setLocalStorageData('fromGallery', { scrollTo: 'swatch' });
            productHandler(e, fromProductList, { index, ppId }, from);
        },
        [skuId, ppId]
    );

    const isAddToCartClick = useCallback(() => {
        let urlStatus;

        if (router?.location) {
            urlStatus = getURLParameterBykey('ppId', router.location.search);
        }
        if (urlStatus === ppId && !invokeAddToCart) {
            invokeAddToCart = ppId;
            goUrl(removeQueryStringParameter(undefined, 'ppId'), true);
            return true;
        }
        return false;
    }, [ppId, router]);

    const priceParams = useMemo(() => {
        const { context: { featureFlags: { enableBundlePriceFix = true } = {} } = {} } = state;
        return getPriceFormatted(
            {
                originalPriceLabel,
                currentPriceLabel,
                originalMax,
                originalMin,
                currentMax,
                currentMin,
                minSavePrice,
                maxSavePrice,
                mapPrice,
                marketingLabel,
                promotionMessages,
                fpacPriceMax,
                fpacPriceMin,
                fpacCoupon,
                brand,
                subDivision,
            },
            enableBundlePriceFix
        );
    }, [ppId]);

    const getBadgeThemeUpperBadge = useMemo(() => {
        return getBadgeThemeData(
            activeUpperBadge,
            true,
            hideTopLeftBadge,
            hideBottomBadge,
            enableProductTitlePrefix,
            skuSwatch,
            bottomBadges
        );
    }, [activeUpperBadge, skuSwatch, hideTopLeftBadge, hideBottomBadge, bottomBadges]);

    const getBadgeThemeBottomBadge = useMemo(() => {
        return getBadgeThemeData(
            bottomBadgeDetails,
            false,
            hideTopLeftBadge,
            hideBottomBadge,
            enableProductTitlePrefix,
            skuSwatch,
            bottomBadges
        );
    }, [activeUpperBadge, skuSwatch, hideTopLeftBadge, hideBottomBadge, bottomBadges]);

    if (Object.keys(productDetails).length !== 0) {
        const imageId = imagesInfo?.thumbnailImageId ?? '';
        const imageUrl =
            imageId &&
            (isDesktopWidth() ? getScene7desktopImageURL(imageId) : getScene7ImageURL(imageId));
        const altImageID = (imagesInfo || {}).altThumbnailImageId;

        const altImageUrl =
            altImageID &&
            (isDesktopWidth()
                ? getScene7desktopImageURL(altImageID)
                : getScene7ImageURL(altImageID));
        const enableCompareIn = compareIn;

        const deafultOffSet = getDefaultOffSetValue();
        const isBoostedPPId = highlightBoostedPPIds && productDetails?.boostedPP;
        const cardClasses = `${dt(['flex', 'w-full', 'bg-light-white'])} ${
            isBoostedPPId && dt(['border-solid', 'border-2', 'border-yellow-300'])
        } ${'ProductCard-productCardPane'}`;
        const sourceUrl = getPageURL();
        const splitUrl = sourceUrl.split('&');
        const sourceUrlFound = splitUrl && splitUrl.find((element) => element.includes(source));
        let pdpUpdatedUrl = pdpUrl;
        if (sourceUrlFound && pdpUrl) {
            pdpUpdatedUrl = pdpUrl.concat(`&${sourceUrlFound}`);
        }
        const skuFilter = useMemo(() => {
            const {
                context: { featureFlags: { enableHandleColorizedImage = true } = {} } = {},
            } = state;
            return skuSwatch && enableHandleColorizedImage
                ? skuSwatch.filter((query) => query.colorizedImageId)
                : skuSwatch;
        }, [skuSwatch]);

        /* if enableReactRenderingStrategy, is true which means product will get loaded only when its in viewport 
            hence lazy loading is not required of images and swatches it will impact performance.
        */
        return (
            <ConditionalWrap
                productWrap={(children) => (
                    <div
                        className={cardClasses}
                        ref={isDesktopWidth() ? cardRefDesktop : cardRefMob}
                    >
                        {children}
                    </div>
                )}
            >
                <ProductCard
                    ref={cardRefDesktop}
                    customClass="productCardPane"
                    cardType={cardType}
                    imageUrl={imageUrl}
                    title={name}
                    isproductList
                    price={priceParams}
                    key={ppId}
                    reviewCount={reviewCount}
                    rating={averageRating}
                    upperBadge={getBadgeThemeUpperBadge}
                    bottomBadge={getBadgeThemeBottomBadge}
                    ecoRebate={ecoRebateAvailable}
                    enableProductTitlePrefix={enableProductTitlePrefix}
                    skuSwatch={skuFilter}
                    cardTheme="gallery"
                    ppId={ppId}
                    pricingConfig={pricingConfig}
                    index={index}
                    skuId={skuId}
                    showSaveForlater
                    altImageUrl={altImageUrl}
                    videoId={videoId}
                    availabilityStatus={availabilityStatus}
                    productUrl={pdpUpdatedUrl}
                    compareIn={enableCompareIn}
                    enableMiniPDP={enableMiniPDP && enableMiniPDPFeature}
                    bulletedCopyAttrs={bulletedCopyAttrs}
                    lazyLoadImages={!enableReactRenderingStrategy}
                    lazyLoadSwatches={!enableReactRenderingStrategy}
                    displayEach={bundleIndicator}
                    defaultOffSet={deafultOffSet}
                    isAddToCartClick={isAddToCartClick()}
                    eligibilityMessage={eligibilityMessage}
                    displayScene7PictureComponent={displayScene7PictureComponent}
                    displayS7PictureComponentAltImg={displayS7PictureComponentAltImg}
                    isEnsembledProduct={entityType === 'ENSEMBLE'}
                    onProductClick={onProductClickEvent}
                    triggerAnalytics={triggerAnalytics}
                    galleryProductCard
                    onImageClick={onImageClickEvent}
                    primaryImageID={imageId}
                    sponsoredProduct={sponsoredProduct}
                    OnBasketChangeBeacon={OnBasketChangeBeacon}
                    OnViewBeacon={OnViewBeacon}
                    OnWishlistBeacon={OnWishlistBeacon}
                    OnClickBeacon={OnClickBeacon}
                />
            </ConditionalWrap>
        );
    }
    return null;
};

GalleryProductCard.propTypes = {
    productDetails: object.isRequired,
    cardType: string,
    fromProductList: bool,
    index: number,
    router: object,
};

GalleryProductCard.defaultProps = {
    cardType: 'list',
    fromProductList: false,
    index: 0,
    router: {},
};

export default withRouter(React.memo(GalleryProductCard));
